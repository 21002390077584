const $ = require('jquery');
const {Router} = require('../../../js/common/router');

/**
 * action btn params
 * @typedef {Object} btnParams
 * @property {string} name - Trad key of button
 * @property {string} path - route to generate for action
 * @property {object} pathQuery - parameters for path generation
 */

export default class Popup {
    /**
     * @param {String} title
     * @param {String} body
     * @param {btnParams} actionBtn
     */
    constructor(title, body, actionBtn = {}) {
        this.title = title;
        this.body = body;
        this.actionBtn = actionBtn;
        this.insertModal();
    }

    insertModal() {
        let title,body,footer;

        if (this.title) {
            title = `<h5 class="modal-title">${Translator.trans(this.title)}</h5>`;
        }

        if (this.body) {
            body = Translator.trans(this.body);
        }

        if (this.actionBtn) {
            footer = `
                <div class="modal-footer">
                      <a href="${Router.generate(this.actionBtn.path, this.actionBtn.pathQuery ?? {})}" type="button" class="btn btn-primary">
                          ${Translator.trans(this.actionBtn.name)}
                      </a>
                </div>
            `;
        }

        const modal = `
            <div class="modal popup-modal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        ${title ?? ''}
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>${body ?? ''}</p>
                    </div>
                        ${footer ?? ''}
                    </div>
                </div>
            </div>
        `;

        document.body.insertAdjacentHTML('afterbegin', modal);
    }

    show() {
        $('.popup-modal').modal('show');
    }
}
