const $ = require('jquery');
require('../scss/linendreamlab.scss');
import {Router} from '../../../js/common/router';
import Cookie from "../../common/js/Cookie";
import Popup from "../../common/js/Popup";
import Constants from "../../common/js/Constants";
import 'select2';

const page = document.querySelector('.page');
const pageSlug = page ? page.getAttribute('data-slug') : '';
const lookSearch = document.querySelector('#photo-search');
const filters = document.querySelector('.filters-container');
const footer = document.querySelector('footer');
const viewer = document.querySelector('#viewer');
const register = document.querySelector('.form-register');
const updateAccount = document.querySelector('.form-update');
const lang = document.documentElement.lang;
const JOB_OTHER = 'other';
const login = document.querySelector('.form-login');
const noResults = document.querySelector('#no-results');

window.CustomizeIframe = iframe => {
    if (iframe.length > 0) {
        const viewerPhotoContainer = viewer.querySelector('.modal-container .carousel-photos');
        let viewerPhotoContainerHeight;

        viewerPhotoContainer.querySelector('.carousel-item img').addEventListener('load', () => {
            viewerPhotoContainerHeight = viewerPhotoContainer.offsetHeight;

            viewerPhotoContainer.setAttribute('style', `height: ${viewerPhotoContainerHeight}px !important;`);
            iframe[0].setAttribute('style', `height: ${viewerPhotoContainerHeight}px !important;`);
        });
    }
};

document.addEventListener('DOMContentLoaded', () => {
    setMainColor();
    handlePopup();
    disableRightClickOnImg();

    if (!login && window.innerWidth > Constants.phonesWidth) {
        setFixedMenu();
    }
    if (document.querySelector('[data-current-route="login.linendreamlab"]')) {
        initLoginDisplay();
    }
    if (page) {
        initPageDisplay();
    }
    if (lookSearch && !noResults) {
        initViewerDisplay();
    }
    if (filters) {
        sortFilters();
        addSeeAllInFilters();
    }
    if (footer) {
        addContactLinkInFooter(footer);
    }
    if (register || updateAccount) {
        initUserForm();
    }
});

const setFixedMenu = () => {
    const segmentsMenu = document.querySelector('.navbar-menu');
    const header = document.querySelector('#header');

    if (segmentsMenu) {
        const headerHeight = header.offsetHeight - 1;
        segmentsMenu.style.setProperty('--header-height', `${headerHeight}px`);
    } else {
        header.classList.add('bottom-border');
    }

    document.querySelector('body').classList.add('fixed-menu');
}

const setMainColor = () => {
    const segment = document.querySelector('body').getAttribute('data-segments');

    if (segment === 'deco') {
        document.documentElement.style.setProperty('--primary-color', '#e7cfc6')
    }
}

const handlePopup = () => {
    const cookie = new Cookie('popup', {'months': 6});

    if (!cookie.get()) {
        document.addEventListener('login', (e) => {
            if (e.detail.logged && (!e.detail.newsletter || !e.detail.optin)) {
                new Popup(
                    'popup.title',
                    'popup.logged',
                    {name: 'update my account', path: `user_account.${lang}`, pathQuery: {showroom: 'linendreamlab'}}
                ).show();
                cookie.create('true', '/', document.domain);
            }

            if (!e.detail.logged) {
                new Popup(
                    'popup.title',
                    'popup.not_logged',
                    {name: 'popup.not_logged_button', path: `register.${lang}`, pathQuery: {showroom: 'linendreamlab'}}
                ).show();
                cookie.create('true', '/', document.domain);
            }
        });
    }
}

const addSeeAllInFilters = () => {
    const trad = Translator.trans('filters.see_all');
    const newOption = new Option(trad, 'see-all', false, false);
    $('.filter-type #type').append(newOption.cloneNode(true));
    $('.filter-designer #designer').append(newOption.cloneNode(true));
    $('.filter-type, .filter-designer').on('select2:selecting', (e) => {
        if (e.params.args.data.id === 'see-all') {
            $('form.filters').on('submit', (e) => e.preventDefault());

            const segment = document.querySelector('[data-segments]').dataset.segments;
            const showroom = segment ? `linendreamlab-${segment}` : 'linendreamlab'
            window.location.href = Router.generate('look_search.' + lang, {showroom});
        }
    })
}

const sortFilters = () => {
    const compositionFilter = $('#composition');
    const quantityFilter = $('#quantity');
    const sectorFilter = $('#sector');

    compositionFilter.select2({
        allowClear: true,
        placeholder: compositionFilter.closest('.filter').attr('data-placeholder'),
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    }).on('change.select2', filterChange);

    sectorFilter.select2({
        allowClear: true,
        placeholder: sectorFilter.closest('.filter').attr('data-placeholder'),
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
    }).on('change.select2', filterChange);

    quantityFilter.select2({
        allowClear: true,
        placeholder: quantityFilter.closest('.filter').attr('data-placeholder'),
        sorter: data => data.sort((a, b) => {
            const regex = /^([a-z]+)[^0-9]+(\d+)/i; //match first word and next group of digits
            const matchesA = a.text.match(regex);
            const matchesB = b.text.match(regex);

            if (matchesA && matchesB && matchesA[1] === matchesB[1]) {
                if (parseInt(matchesA[2]) > parseInt(matchesB[2])) {
                    return 1;
                }

                return -1;
            }

            return 1;
        }),
    }).on('change.select2', filterChange);
}

const filterChange = () => {
    $('form.filters').trigger('submit');
    $('.filter').find('select, input').prop('disabled', true);
}

const initLoginDisplay = () => {
    document.querySelector('body').classList.add('login');

    const leftContainer = document.createElement('div');
    const rightContainerLink = document.createElement('a');

    leftContainer.classList.add('left-container');
    leftContainer.appendChild(document.querySelector('header .website-logo'));
    leftContainer.appendChild(document.querySelector('footer'));

    rightContainerLink.href = '#';
    rightContainerLink.classList.add('homepage-link');
    rightContainerLink.innerHTML = 'Go back';
    rightContainerLink.addEventListener('click', e => {
        e.preventDefault();
        history.back();
    });

    document.querySelector('.form-login').insertAdjacentElement('beforeend', rightContainerLink);
    document.querySelector('#content').insertAdjacentElement('afterbegin', leftContainer);
}

const initViewerDisplay = () => {
    import('../../search/js/_viewer').then(() => {
        viewer.addEventListener('initcarouselcontent', (e) => {
            [...e.currentTarget.querySelectorAll('.carousel-media-description a')]
                .forEach(link => link.setAttribute('target', '_blank'));

            const custom = e.data.customFields;
            const certifications = (custom || { certifications : [] })
                .certification
                .filter(certif => ['masters-of-linen', 'european-flax'].indexOf(certif) !== -1)
                .map(certif => certif === 'masters-of-linen'
                    ? "https://cdn.tag-walk.com/showroom/linendreamlab/pages/certifications/certification_masters_of_linen.jpg"
                    : "https://cdn.tag-walk.com/showroom/linendreamlab/pages/certifications/certification_ef_vertical.jpg"
                )
                .map(url => `
                    <a href="/linendreamlab/page/sustainability-certifications#certifications">
                        <img src="${url}"/>
                    </a>
                `);


            const oldContainer = e.target.querySelector('.certifications');

            if (!!oldContainer) {
                oldContainer.parentElement.removeChild(oldContainer);
            }

            if (certifications.length === 0) {
                return;
            }

            const certifContainer = document.createElement('div');
            certifContainer.classList.add('certifications');
            certifContainer.innerHTML = `
                <span>Certifications :</span>
                <br>
                ${certifications.join('')}
            `;

            const description = e.target.querySelector('.carousel-media-description');
            description.insertAdjacentHTML('afterend', certifContainer.outerHTML);
        })
    })
    const viewer = document.querySelector('#viewer');
    const icon = '<i class="fal fa-plus-circle"></i>';
    const perfomancePage = Router.generate('page_show.' + lang, {slug: 'performance', showroom: 'linendreamlab'});
    const blop = `<div class="bottom-clearfix"><span>From same company</span><a href="${perfomancePage}">Did you know ?</a></div>`;

    viewer.querySelector('.requests a').insertAdjacentHTML('afterbegin', icon);
    viewer.querySelector('.carousel-clearfix').insertAdjacentHTML('beforeend', blop);
}

const disableRightClickOnImg = () => {
    document.addEventListener('contextmenu',  (e) => {
        if (e.target.tagName === 'IMG') {
            e.preventDefault();
        }
    }, false);
}

const initPageDisplay = () => {
    [...document.querySelectorAll('.page p')]
        .filter(p => p.textContent === '[break]')
        .forEach(p => {
            const line = document.createElement('hr');
            p.replaceWith(line);
        });

    [...document.querySelectorAll('.page p')]
        .filter(p => p.textContent === '[image]')
        .forEach(p => {
            const div = createMockupImg();
            p.replaceWith(div);
        });

    [...document.querySelectorAll('.page p')]
        .filter(p => !!p.textContent.match(/\[[\w\.\/:_+,%-]+\]/))
        .forEach(p => {
            const length = p.textContent.length;
            const src = p.textContent.substr(1, length - 2);

            const img = document.createElement('img');
            img.setAttribute('src', src);

            p.replaceWith(img);
        });

    [...document.querySelectorAll('.page td')]
        .filter(td => !!td.textContent.match(/\[[\w\.\/:_+,%-]+\]/))
        .forEach(td => {
            if (td.textContent === '[image]') {
                const div = createMockupImg();
                td.innerHTML = div.outerHTML;
            } else {
                const length = td.textContent.length;
                const src = td.textContent.substr(1, length - 2);

                const img = document.createElement('img');
                img.setAttribute('src', src);

                td.innerHTML = img.outerHTML;
            }
        });

    /**
     * Convert any table into a boostrap grid
     *
     * Each td is replaced by a <div class="col"></div>
     * Each tr is replaced by a <div class="row"></div>
     *
     * Everything else is ignored
     * Finally the initial table is removed
     */
    [...document.querySelectorAll('.page table')]
        .forEach((table, i) => {
            if (pageSlug === 'legal-notice-data-privacy') {
                return;
            }

            [...table.getElementsByTagName('tr')]
                .forEach(line => tableToGrid(line, i));

            [...table.querySelectorAll('div .row')]
                .forEach(row => {
                    table.parentNode.appendChild(row);
                });

            table.parentNode.removeChild(table);
        });

    /**
     * In case the table is surrounded by a
     * <figure class="table"></figure> element
     */
    [...document.querySelectorAll('.page figure[class="table"]')]
        .forEach(figure => {
            while (figure.childNodes.length > 0) {
                // insert before to preserve the position
                figure.parentElement.insertBefore(figure.childNodes[0], figure);
            }

            figure.parentElement.removeChild(figure);
        });

    [...document.querySelectorAll('.page a')]
        .forEach(a => {
            if ((a.getAttribute('href') || '').indexOf('#') !== 0 &&
                (a.hostname !== window.location.hostname)
            ) {
                a.setAttribute('target', '_blank');
            } else {
                a.setAttribute('id', a.getAttribute('href').substr(1));
            }
        })

    if (pageSlug === 'european-industry') {
        [...document.querySelectorAll('.european-industry-step-container .col-inner')]
            .forEach(block => {
                const div = document.createElement('div');
                div.classList.add('european-industry-step-text');

                [...block.childNodes]
                    .filter((node, i) => i > 0)
                    .forEach(node => div.appendChild(node));

                if (block.childNodes.length > 0 && block.childNodes[0].tagName === 'IMG') {
                    const src = block.childNodes[0].getAttribute('src');
                    block.style.backgroundImage = 'url(' + src + ')';
                    block.style.backgroundSize = 'cover';
                    block.style.backgroundPosition = 'center';

                    block.removeChild(block.childNodes[0]);
                }

                block.appendChild(div);
            });
    }

    if (pageSlug === 'services') {
        [...document.querySelectorAll('.services-step-container .col-inner')]
            .forEach(block => {
                const div = document.createElement('div');
                div.classList.add('services-step-text');

                [...block.childNodes]
                    .filter((node, i) => i > 0)
                    .forEach(node => div.appendChild(node));

                if (block.childNodes.length > 0 && block.childNodes[0].tagName === 'IMG') {
                    const src = block.childNodes[0].getAttribute('src');
                    block.style.backgroundImage = 'url(' + src + ')';
                    block.classList.add('services-section-banner');

                    const row = block.closest('.row');

                    if (!!row) {
                        row.classList.add('services-section-header');
                    }

                    block.removeChild(block.childNodes[0]);
                }

                block.appendChild(div);
            });
    }

    const images = document.querySelectorAll('[data-src-mobile]');

    if (images) {
        resizeTrendsImages(images);

        window.addEventListener('resize', () => {
            resizeTrendsImages(images);
        });
    }
}

function isTrendSection(cells) {
    if (cells.length !== 2) {
        return false;
    }

    if (cells[0].childNodes.length !== 1) {
        return false;
    }

    return cells[0].childNodes[0].tagName === 'IMG'
        || cells[0].childNodes[0].classList.contains('mockup-img');
}

function cellToColDiv(cell, classes) {
    const div = document.createElement('div');
    const innerContent = cell.innerHTML;

    if (innerContent.split("&nbsp;").join(" ").trim() === '') {
        classes.push('d-none');
    }

    div.setAttribute('class', classes.join(' '));
    div.innerHTML = '<div class="col-inner">' + innerContent + '</div>';
    cell.replaceWith(div);
    return div;
}

function isPhotoCarousel(cells) {
    return cells
        .map(cell => cell.childNodes.length === 1 && cell.childNodes[0].tagName === 'IMG')
        .reduce((acc, curr) => acc && curr, true);
}

function createMockupImg() {
    const div = document.createElement('div');
    div.setAttribute('class', 'mockup-img');

    const text = document.createTextNode("Illustration à définir");
    div.appendChild(text);

    return div;
}

function tableToGrid(line, index) {
    if (pageSlug === 'legal-notice-data-privacy') {
        return;
    }

    const cells = [...line.getElementsByTagName('td')];
    const isCarousel = isPhotoCarousel(cells);
    const isSection = !isCarousel && isTrendSection(cells);

    if (isSection) {
        switch (pageSlug) {
            case "european-industry": {
                const img = cellToColDiv(cells[0], ['col-12', 'col-md-4']);
                const section = cellToColDiv(cells[1], ['col-12', 'col-md-8']);
                break;
            }

            case "sustainability-certifications": {
                const img = cellToColDiv(cells[0], ['col-12', 'col-xl-5']);
                const section = cellToColDiv(cells[1], ['col-12', 'col-xl-7']);
                break;
            }

            case "performance": {
                const img = cellToColDiv(cells[0], ['col-12', 'col-xl-5']);
                const section = cellToColDiv(cells[1], ['col-12', 'col-xl-7']);
                break;
            }

            default: {
                const img = cellToColDiv(cells[0], ['col-12', 'col-xl-8']);
                const section = cellToColDiv(cells[1], ['col-12', 'col-xl-4']);
                break;
            }
        }

    } else if (isCarousel) {
        cells.forEach(cell => cellToColDiv(cell, ['carousel-img']));
    } else {
        let gridLevels = ['col', 'col-12'];

        if (cells.length === 2) {
            gridLevels.push('col-xl-6');
        } else if (cells.length === 3) {
            gridLevels.push('col-xl-4');
        } else if (cells.length === 4) {
            gridLevels.push('col-xl-3');
        } else if (cells.length !== 1) {
            gridLevels.push('col-xl-3');
        }

        cells.forEach(cell => cellToColDiv(cell, gridLevels));
    }

    const div = document.createElement('div');

    if (isCarousel) {
        div.setAttribute('class', 'row carousel-container d-flex flex-row-reverse');
    } else {
        div.setAttribute('class', 'row justify-content-md-center');
    }

    div.classList.add('page-' + pageSlug);

    if (isSection) {
        div.classList.add(pageSlug + '-section-container');
    } else {
        div.classList.add(pageSlug + '-step-container');
    }

    div.innerHTML = line.innerHTML;
    line.replaceWith(div);
}

const addContactLinkInFooter = footer => {
    const email = 'ue.pmehnenilxalfecnailla@noitacinummoc'
    const contactLink = document.createElement('a');

    contactLink.href = `#`;
    contactLink.innerHTML = Translator.trans('footer.contact_us');
    contactLink.addEventListener('click', () => {
        contactLink.href = `mailto:${email.split('').reverse().join('')}`;
    });

    const legal = footer.querySelector('.legal-links');

    if (legal) {
        legal.insertAdjacentElement('beforeend', contactLink);
    }
};

const initUserForm = () => {
    const form = document.querySelector('form');
    const jobTitle = form.querySelector('#jobTitle');

    if (jobTitle) {
        handleJobTitle(jobTitle);
    }
};

const handleJobTitle = jobTitle => {
    jobTitle.closest('.form-group').style.display = 'none';
    const copy = jobTitle.cloneNode(true);
    const customJobField = document.querySelector('#custom_field_job');

    if (customJobField.value === JOB_OTHER) {
        const select = customJobField.closest('.form-group');

        copy.setAttribute('required', 'required');
        select.appendChild(copy);
    }

    $(customJobField).on('select2:select', e => {
        const select = e.target.closest('.form-group');

        if (e.target.value === JOB_OTHER) {
            copy.setAttribute('required', 'required');
            select.appendChild(copy);
            copy.focus();
            copy.select();
        } else {
            jobTitle.value = '';
            copy.remove();
        }
    });
};

const resizeTrendsImages = images => {
    if (window.innerWidth >= 992) {
        Array.from(images).forEach(image => {
            image.setAttribute('src', image.dataset.src);
        });
    } else {
        Array.from(images).forEach(image => {
            image.setAttribute('src', image.dataset.srcMobile);
        });
    }
}
