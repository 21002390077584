import {DateTime} from "luxon";

export default class Cookie {
    constructor(name, duration) {
        this.name = document.documentElement.getAttribute('data-showroom') + '-' + name;
        this.duration = duration;
    }

    get() {
        let decodedCookie = decodeURIComponent(document.cookie),
            cookie = decodedCookie.split(';');

        for (let i = 0; i < cookie.length; i++) {
            if (cookie[i].trim().indexOf(this.name) === 0) {
                return true;
            }
        }

        return false;
    }

    create(value, path, domain) {
        let cookie = this.name + "=" + value + ";";

        cookie += "expires=" + DateTime.local().plus(this.duration).toHTTP() + ";";

        if (path) {
            cookie += "path=" + path + ";";
        }

        if (domain) {
            cookie += "domain=" + domain + ";";
        }

        document.cookie = cookie + "SameSite=Strict;";
    }
}
