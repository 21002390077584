export default class Constants {
    static get largeDesktopWidth() {
        return 1200;
    }
    static get desktopWidth () {
        return 992;
    }
    static get tabletWidth() {
        return 768;
    }
    static get phonesWidth() {
        return 576;
    }
}
